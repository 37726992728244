import { deepCompare } from "@website/utils";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { FC, memo, useState } from "react";
import CarouselDots from "./carousel-dots";
import CarouselNavigation from "./carousel-navigation";
import { CarouselPropertiesType } from "./carousel.types";

const Component: FC<CarouselPropertiesType> = ({
  options,
  list,
  keyCarousel,
  hasNavigation = true,
  itemClass,
  hasDots,
  mode
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderReference, instanceReference] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    rtl: true,
    renderMode: "performance",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    ...options,
    mode
  });

  return (
    <article className="group relative size-full">
      <section className="keen-slider size-full" ref={sliderReference}>
        {list?.map((component, index) => (
          <article
            className={`keen-slider__slide ${itemClass} !overflow-visible`}
            key={`${keyCarousel}-slide-${index++}`}
          >
            {component}
          </article>
        ))}
      </section>
      {Boolean(hasNavigation) && loaded && instanceReference.current && (
        <CarouselNavigation
          currentReference={instanceReference.current}
          currentSlide={currentSlide}
        />
      )}
      {Boolean(hasDots) && loaded && instanceReference.current && (
        <CarouselDots
          currentReference={instanceReference.current}
          currentSlide={currentSlide}
          keyCarousel={keyCarousel}
        />
      )}
    </article>
  );
};

export const CarouselV2 = memo(Component, deepCompare);
