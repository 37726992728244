/* eslint-disable react/jsx-no-bind */
import { CarouselDotsPropertiesType } from "./carousel.types";

const CarouselDots = ({
  currentSlide,
  currentReference,
  keyCarousel
}: CarouselDotsPropertiesType) => (
  <div className="bg-on-color-disable absolute bottom-4 right-1/2 z-20 flex translate-x-1/2 gap-1 rounded-full px-2.5 py-1 max-md:hidden">
    {[
      ...Array.from({
        length: currentReference.track.details.slides.length
      }).keys()
    ].map((index) => (
      <button
        className={`size-1.5 rounded-full ${
          currentSlide === index
            ? "bg-on-surface-secondary-high-emphasis"
            : "bg-on-color-disable"
        }`}
        key={`${keyCarousel}-dot-${index}`}
        onClick={() => currentReference.moveToIdx(index)}
      />
    ))}
  </div>
);

export default CarouselDots;
